import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import Quasar from 'quasar/src/vue-plugin.js';import Notify from 'quasar/src/plugins/notify/Notify.js';import Loading from 'quasar/src/plugins/loading/Loading.js';import Dialog from 'quasar/src/plugins/dialog/Dialog.js';;
import quasarUserOptions from "./quasar-user-options";
import axios from "axios";

const app = createApp(App);

app.use(Quasar, {
  plugins: {
    Notify,
    Loading,
    Dialog,
  },
  config: {
    loading: {},
  },
  quasarUserOptions,
});

app.use(store).use(router);

// provide
app.provide(
  "$http",
  axios.create({
    baseURL: process.env.VUE_APP_BASE_URL,
  })
);

app.mount("#app");
