import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    name: "home",
    component: () => import("../views/HomeView.vue"),
  },
  {
    path: "/profile",
    name: "profile",
    component: () => import("../views/ProfileView.vue"),
  },
  {
    path: "/faq",
    name: "faq",
    component: () => import("../views/FAQView.vue"),
  },
  {
    path: "/contact",
    name: "contact",
    component: () => import("../views/ContactView.vue"),
  },
  {
    path: "/concrete",
    name: "concrete",
    component: () => import("../views/ProductConcrete.vue"),
  },
  {
    path: "/mine",
    name: "mine",
    component: () => import("../views/ProductMine.vue"),
  },
  {
    path: "/article",
    name: "article",
    component: () => import("../views/MediaArticle.vue"),
  },
  {
    path: "/article-detail/:id",
    name: "articleDetail",
    component: () => import("../views/MediaArticleDetail.vue"),
  },
  {
    path: "/news",
    name: "news",
    component: () => import("../views/MediaNews.vue"),
  },
  {
    path: "/news-detail/:id",
    name: "newsDetail",
    component: () => import("../views/MediaNewsDetail.vue"),
  },
  {
    path: "/gallery",
    name: "gallery",
    component: () => import("../views/MediaGallery.vue"),
  },
  {
    path: "/about",
    name: "about",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/AboutView.vue"),
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
  scrollBehavior(to, from, savedPosition) {
    return { top: 0 };
  },
});

export default router;
