<template>
  <q-layout view="lHh Lpr lFf ">
    <q-header class="bg-white my-font">
      <div
        v-if="!isMobile && !isTablet"
        class="l-grow bg-grey-3 row justify-between q-px-lg q-py-xs text-grey"
        style="font-size: 12px"
      >
        <div class="items-center row q-gutter-x-sm">
          <q-icon name="watch_later" /> <span>Senin-Jumat : 07.00 - 17.00</span>
        </div>
        <div class="row q-gutter-x-sm">
          <div class="items-center row q-gutter-x-sm">
            <q-icon name="phone" />
            <span>(021) 5937 1878</span>
          </div>
          <div class="items-center row q-gutter-x-sm">
            <q-icon name="email" />
            <span>general@ligno.co.id</span>
          </div>
        </div>
      </div>
      <q-toolbar class="q-py-xs">
        <q-img
          :src="`./img/ligno.png`"
          height="40px"
          width="115px"
          fit="contain"
          @click="
            router.push('/');
            data.tab = null;
          "
          class="cursor-pointer"
        ></q-img>
        <q-space></q-space>
        <q-tabs
          v-if="!isMobile && !isTablet"
          v-model="data.tab"
          active-color="primary"
          class="text-black q-gutter-x-md row"
          no-caps
        >
          <q-route-tab name="home" label="Home" to="/" />
          <q-route-tab name="profile" label="Profile" to="/profile" />
          <div
            :style="
              route.name == 'concrete' || route.name == 'mine'
                ? 'border-bottom:  2px solid #027be3'
                : 'border-bottom:  2px solid white'
            "
            class="q-ma-none column justify-center"
            style="height: 100%; padding-top: 2px"
          >
            <q-btn-dropdown
              label="Produk"
              v-model="menuProduk"
              flat
              :color="
                route.name == 'concrete' || route.name == 'mine'
                  ? 'primary'
                  : 'black'
              "
              no-caps
              auto-close
              stretch
              class="q-ma-none"
            >
              <q-list style="width: 150px">
                <q-item clickable v-close-popup to="/concrete">
                  <q-item-section>
                    <q-img
                      :src="`./img/menu_produk1.png`"
                      height="32px"
                      fit="contain"
                    ></q-img>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Industri Beton</q-item-label>
                  </q-item-section>
                </q-item>
                <q-item clickable v-close-popup to="/mine">
                  <q-item-section>
                    <q-img
                      :src="`./img/menu_produk2.png`"
                      height="32px"
                      fit="contain"
                    ></q-img>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Industri Tambang</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </div>

          <div
            :style="
              route.name == 'article' ||
              route.name == 'articleDetail' ||
              route.name == 'gallery' ||
              route.name == 'news' ||
              route.name == 'newsDetail'
                ? 'border-bottom:  2px solid #027be3'
                : 'border-bottom:  2px solid white'
            "
            class="q-ma-none column justify-center"
            style="height: 100%; padding-top: 2px"
          >
            <q-btn-dropdown
              label="Media"
              v-model="menuMedia"
              flat
              :color="
                route.name == 'article' ||
                route.name == 'articleDetail' ||
                route.name == 'gallery' ||
                route.name == 'news' ||
                route.name == 'newsDetail'
                  ? 'primary'
                  : 'black'
              "
              no-caps
              class="q-ma-none"
              auto-close
              stretch
            >
              <q-list style="width: 150px">
                <q-item clickable v-close-popup to="/gallery">
                  <q-item-section>
                    <q-img
                      :src="`./img/menu_gambar.png`"
                      height="32px"
                      fit="contain"
                    ></q-img>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Gambar</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup to="/article">
                  <q-item-section>
                    <q-img
                      :src="`./img/menu_artikel.png`"
                      height="32px"
                      fit="contain"
                    ></q-img>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Artikel</q-item-label>
                  </q-item-section>
                </q-item>

                <q-item clickable v-close-popup to="/news">
                  <q-item-section>
                    <q-img
                      :src="`./img/menu_berita.png`"
                      height="32px"
                      fit="contain"
                    ></q-img>
                  </q-item-section>
                  <q-item-section>
                    <q-item-label>Berita</q-item-label>
                  </q-item-section>
                </q-item>
              </q-list>
            </q-btn-dropdown>
          </div>

          <q-route-tab name="faq" label="FAQ" to="/faq" />
        </q-tabs>

        <q-space></q-space>
        <div
          v-if="!isMobile && !isTablet"
          class="row items-center q-gutter-x-sm"
        >
          <!-- <q-btn icon="search" color="red" flat dense /> -->
          <q-btn
            unelevated
            no-caps
            label="Hubungi Kami"
            color="primary"
            @click="
              router.push('/contact');
              data.tab = null;
            "
          />
        </div>

        <!-- Mobile Menu -->
        <q-btn
          v-if="isMobile || isTablet"
          flat
          round
          icon="menu"
          color="black"
          @click="leftDrawerOpen = true"
        />
      </q-toolbar>
      <!-- Drawer for Mobile Navigation -->
      <q-drawer
        v-model="leftDrawerOpen"
        side="left"
        overlay
        behavior="mobile"
        class="bg-white column"
        style="height: 100%; width: 100vw !important"
      >
        <!-- Drawer Header -->
        <div class="q-px-md q-py-sm row items-center justify-end">
          <!-- <q-img
            :src="`./img/ligno.png`"
            height="40px"
            width="115px"
            fit="contain"
            alt="Logo"
          /> -->
          <q-btn
            flat
            round
            icon="close"
            color="black"
            @click="leftDrawerOpen = false"
          />
        </div>
        <q-separator class="bg-red" size="3px" />

        <!-- Search Bar -->
        <!-- <div>
          <q-input
            v-model="data.search"
            rounded
            outlined
            dense
            placeholder="Pencarian"
            style="max-width: 1000px"
            class="l-grow q-pa-sm"
            bg-color="grey-1"
          >
            <template v-slot:append>
              <q-icon color="grey" name="search" />
            </template>
          </q-input>
        </div> -->
        <div>
          <!-- Menu Items -->
          <q-list class="text-black">
            <q-item clickable v-ripple v-close-popup to="/">
              <q-item-section>Home</q-item-section>
            </q-item>

            <q-item clickable v-ripple v-close-popup to="/profile">
              <q-item-section>Profile</q-item-section>
            </q-item>

            <!-- Produk Section with Submenus -->
            <q-expansion-item label="Produk" dense>
              <q-item clickable v-ripple v-close-popup to="/concrete">
                <q-item-section avatar>
                  <q-img
                    :src="`./img/menu_produk1.png`"
                    height="32px"
                    fit="contain"
                  ></q-img>
                </q-item-section>
                <q-item-section>Industri Beton</q-item-section>
              </q-item>
              <q-item clickable v-ripple v-close-popup to="/mine">
                <q-item-section avatar>
                  <q-img
                    :src="`./img/menu_produk2.png`"
                    height="32px"
                    fit="contain"
                  ></q-img>
                </q-item-section>
                <q-item-section>Industri Tambang</q-item-section>
              </q-item>
            </q-expansion-item>

            <!-- Media Section with Submenus -->
            <q-expansion-item class="q-pt-sm" label="Media" dense>
              <q-item clickable v-ripple v-close-popup to="/gallery">
                <q-item-section avatar>
                  <q-img
                    :src="`./img/menu_gambar.png`"
                    height="32px"
                    fit="contain"
                  ></q-img>
                </q-item-section>
                <q-item-section>Gambar</q-item-section>
              </q-item>
              <q-item clickable v-ripple v-close-popup to="/article">
                <q-item-section avatar>
                  <q-img
                    :src="`./img/menu_artikel.png`"
                    height="32px"
                    fit="contain"
                  ></q-img>
                </q-item-section>
                <q-item-section>Artikel</q-item-section>
              </q-item>
              <q-item clickable v-ripple v-close-popup to="/news">
                <q-item-section avatar>
                  <q-img
                    :src="`./img/menu_berita.png`"
                    height="32px"
                    fit="contain"
                  ></q-img>
                </q-item-section>
                <q-item-section>Berita</q-item-section>
              </q-item>
            </q-expansion-item>

            <q-item clickable v-ripple v-close-popup to="/faq">
              <q-item-section>FAQ</q-item-section>
            </q-item>
          </q-list>
        </div>

        <q-space />
        <!-- Hubungi Kami Button -->
        <div class="q-pa-md column justify-end">
          <q-btn
            unelevated
            no-caps
            label="Hubungi Kami"
            color="primary"
            @click="
              router.push('/contact');
              data.tab = null;
            "
          />
        </div>
      </q-drawer>
      <q-separator class="bg-red" size="3px" />
    </q-header>

    <q-page-container>
      <router-view class="q-mx-auto my-font" />
    </q-page-container>

    <div
      v-if="
        route.name === 'home' ||
        route.name === 'profile' ||
        route.name === 'concrete' ||
        route.name === 'mine'
      "
      class="background-line-2 column justify-between my-font"
    >
      <q-space />
      <div class="background-container column justify-end container-footer">
        <div class="blue-overlay-2">
          <div
            :class="isMobile || isTablet ? 'wrap' : 'no-wrap'"
            class="row l-mobile-container justify-between"
            style="height: 100%"
          >
            <div
              class="text-white column justify-center pa-xl-to-md q-gutter-y-sm"
              style="
                max-width: 900px;
                min-width: 350px;
                white-space: normal !important;
              "
            >
              <div class="l-text-title-3">
                Siap Meningkatkan <b>Produktivitas</b> Kebutuhan Industri Anda?
              </div>
              <div class="l-text-description">
                Temukan Solusi yang paling sesuai untuk kebutuhan Industri Anda
              </div>
              <div>
                <q-btn
                  label="Hubungi Kami"
                  text-color="white"
                  color="red"
                  rounded
                  @click="
                    router.push('/contact');
                    data.tab = null;
                  "
                />
              </div>
            </div>
            <div class="column justify-end items-end img-footer l-grow">
              <q-img
                :src="`./img/footer1.webp`"
                class="l-footer-img"
                fit="contain"
              />
              <!-- <div style="margin-top: -150px; margin-left: 250px">
              </div> -->
            </div>
          </div>
        </div>
      </div>
    </div>

    <!-- footer -->
    <div class="background-line-footer my-font">
      <div class="row justify-between l-mobile-container pa-xl-to-md">
        <div class="row q-gutter-md q-mb-md">
          <div class="column items-center q-gutter-y-sm">
            <q-img :src="`./img/ligno.png`" width="235px" fit="contain"></q-img>
            <div class="text-white text-bold">
              PT. LIGNO SPECIALTY CHEMICALS
            </div>
          </div>
          <div class="text-white column q-gutter-y-sm" style="font-size: 14px">
            <div style="width: 300px">
              Kawasan Industri Mekar Jaya KM.7 Jl. Karet IV No. 36 - 40, Mekar
              Jaya, Sepatan Kab. Tangerang, Banten 15520
            </div>
            <div>
              tel :
              <a :href="`tel:+622159371878`" style="color: white">
                (021) 5937 1878
              </a>
            </div>
            <div>fax: (021) 5937 1062</div>
            <div>
              WA:
              <u
                class="cursor-pointer"
                @click="window.open(data.selectedNumber.link, '_blank')"
                >{{ data.selectedNumber.number }}</u
              >
              ({{ data.selectedNumber.name }})
            </div>
          </div>
        </div>
        <div class="row q-gutter-x-sm items-center">
          <div>
            <q-btn dense flat>
              <q-img
                :src="`./img/instagram.png`"
                class="l-footer-logo"
                fit="contain"
              ></q-img>
            </q-btn>
          </div>
          <div>
            <q-btn dense flat>
              <q-img
                :src="`./img/twitter.png`"
                class="l-footer-logo"
                fit="contain"
              ></q-img>
            </q-btn>
          </div>
          <div>
            <q-btn dense flat>
              <q-img
                :src="`./img/facebook.png`"
                class="l-footer-logo"
                fit="contain"
              ></q-img>
            </q-btn>
          </div>
          <div>
            <q-btn
              dense
              flat
              @click="
                window.open(
                  'https://www.youtube.com/channel/UCjBVG0BYuWix3hBblc9mFPA',
                  '_blank'
                )
              "
            >
              <q-img
                :src="`./img/youtube.png`"
                class="l-footer-logo"
                fit="contain"
              ></q-img>
            </q-btn>
          </div>
          <!-- <q-img :src="`./img/twitter.png`" width="48px" fit="contain"></q-img>
          <q-img :src="`./img/facebook.png`" width="48px" fit="contain"></q-img>
          <q-img :src="`./img/youtube.png`" width="48px" fit="contain"></q-img> -->
        </div>
      </div>
      <q-separator color="grey" />
      <div
        :class="
          isMobile || isTablet
            ? 'column items-center'
            : 'row justify-between items-center'
        "
        class="px-xl-to-md l-mobile-container q-py-md text-grey"
      >
        <div>©2024 PT LIGNO SPECIALTY CHEMICALS</div>
        <div class="row q-gutter-x-sm">
          <q-btn label="Privacy Policy" flat no-caps color="grey" />
          <q-btn label="Terms & Condition" flat no-caps color="grey" />
        </div>
      </div>
    </div>
  </q-layout>
</template>
<!-- @click="window.open(data.selectedNumber, '_blank')" -->
<script>
import useBasic from "@/composables/useBasic";

export default {
  setup() {
    const { ref, reactive, router, route, watch, onMounted, $q, computed } =
      useBasic();

    let data = reactive({
      search: null,
      tab: null,
      selectedNumber: "",
      WAnumbers: [
        {
          name: "Agung",
          number: "0813 8303 6313",
          link: "https://wa.me/+6281383036313",
        },
        {
          name: "Abdel",
          number: "0858 8535 1646",
          link: "https://wa.me/+6285885351646",
        },
        {
          name: "Dika",
          number: "0813 6968 7403",
          link: "https://wa.me/+6281369687403",
        },
        {
          name: "Farid",
          number: "0896 2826 4826",
          link: "https://wa.me/+6289628264826",
        },
      ],
    });

    // Check if the current screen is mobile-sized
    let isMobile = computed(() => $q.screen.width <= 500);

    // Check if the current screen is mobile-sized
    let isTablet = computed(() => $q.screen.width <= 800);

    // let tab = ref(null);

    watch(
      () => route.name, // Watching the route name
      (newRouteName) => {
        // console.log("Route name changed to:", newRouteName);
        if (newRouteName === "home" || newRouteName === "contact") {
          data.tab = null;
        }
      }
    );

    onMounted(async () => {
      //get WA number (random)
      let randomIndex = Math.floor(Math.random() * data.WAnumbers.length);
      data.selectedNumber = data.WAnumbers[randomIndex];
    });

    return {
      leftDrawerOpen: ref(false),
      menuProduk: ref(null),
      menuMedia: ref(null),
      // tab,
      data,
      router,
      route,
      window,
      isMobile,
      isTablet,
    };
  },
};
</script>

<style lang="scss">
@import "@/styles/quasar.scss";
.w400-to-320 {
  width: 400px !important;
  @media only screen and (max-width: 500px) {
    width: 320px !important;
  }
}

.px-xl-to-md {
  padding: 10px 58px;
  @media only screen and (max-width: 500px) {
    padding: 10px 18px;
  }
}

.pa-xl-to-md {
  padding: 58px;
  @media only screen and (max-width: 500px) {
    padding: 18px;
  }
}

.background-line-footer {
  position: relative;
  background-image: url("@/assets/line-bg3.jpg");
  background-size: cover;
  background-position: center;
  background-color: black;
}

.background-container {
  /* height: 900px; */
  position: relative;
  background-image: url("@/assets/storage.webp");
  background-size: cover;
  background-position: center;
}

.container-footer {
  height: 400px;
  @media only screen and (max-width: 800px) {
    height: 600px;
  }
  @media only screen and (max-width: 500px) {
    height: 500px;
  }
}

.img-footer {
  margin-top: -150px;
  width: 400px;
}

.l-footer-img {
  width: 400px;
  @media only screen and (max-width: 800px) {
    width: 320px !important;
  }
  @media only screen and (max-width: 500px) {
    width: 240px !important;
  }
}

.background-line-2 {
  height: 600px;
  position: relative;
  background-image: url("@/assets/line-bg2.png");
  background-size: cover;
  background-position: center;
  @media only screen and (max-width: 800px) {
    height: 700px !important;
  }
}

.blue-overlay-2 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    270deg,
    rgba(0, 132, 243, 1) 0%,
    rgba(0, 132, 243, 0.8) 100%
  );
  z-index: 1; /* Make sure the overlay is above the background */
  @media only screen and (max-width: 800px) {
    background: #0084f3;
  }
}

.l-container {
  max-width: 1440px !important;
}

.l-mobile-container {
  max-width: 1440px;
  min-width: 300px;

  margin: 0 auto;
}

.l-grow {
  flex-grow: 99 !important;
}

.l-footer-logo {
  width: 48px !important;
  @media only screen and (max-width: 500px) {
    width: 24px !important;
  }
}

.kiri-radius {
  border: 1px solid #bbbbbb;
  border-radius: 8px 0 0 8px !important;
}

.kanan-radius {
  border: 1px solid #bbbbbb;
  border-radius: 0 8px 8px 0 !important;
}
.atas-radius {
  border: 1px solid #bbbbbb;
  border-radius: 8px 8px 0 0 !important;
}
.bawah-radius {
  border: 1px solid #bbbbbb;
  border-radius: 0 0 8px 8px !important;
}

.all-radius {
  border: 1px solid #bbbbbb;
  border-radius: 8px 8px 8px 8px !important;
}

@font-face {
  font-family: customfont;
  src: url(./styles/fonts/Montserrat-Regular.ttf);
}

@font-face {
  font-family: customfont;
  src: url("./styles/fonts/Montserrat-Bold.ttf");
  font-weight: 700;
}

@font-face {
  font-family: customfont;
  src: url("./styles/fonts/Montserrat-SemiBold.ttf");
  font-weight: 500;
}

@font-face {
  font-family: customfont;
  src: url("./styles/fonts/Montserrat-Light.ttf");
  font-weight: 300;
}

@font-face {
  font-family: customfont;
  src: url("./styles/fonts/Montserrat-Thin.ttf");
  font-weight: 200;
}

.my-font {
  font-family: "customfont";
}

.l-text-header {
  font-size: 64px !important;
  line-height: 1;
  @media only screen and (max-width: 800px) {
    font-size: 36px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 24px !important;
  }
}

.l-text-title {
  font-size: 48px !important;
  // line-height: 1.8;
  @media only screen and (max-width: 800px) {
    font-size: 38px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 32px !important;
  }
}

.l-text-title-2 {
  font-size: 38px !important;
  @media only screen and (max-width: 800px) {
    font-size: 20px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 18px !important;
  }
}

.l-text-title-3 {
  font-size: 36px !important;
  // line-height: 1.8;
  @media only screen and (max-width: 800px) {
    font-size: 30px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 24px !important;
  }
}

.l-text-title-4 {
  font-size: 48px !important;
  // line-height: 1.8;
  @media only screen and (max-width: 800px) {
    font-size: 36px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 24px !important;
  }
}

.l-text-title-5 {
  font-size: 32px !important;
  @media only screen and (max-width: 800px) {
    font-size: 28px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 24px !important;
  }
}

.l-text-subtitle {
  font-size: 24px !important;
  // line-height: 1.8;
  @media only screen and (max-width: 800px) {
    font-size: 18px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 16px !important;
  }
}

.l-text-subtitle-2 {
  font-size: 24px !important;
  // line-height: 1.8;
  @media only screen and (max-width: 800px) {
    font-size: 20px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 18px !important;
  }
}

.l-text-detail {
  font-size: 18px !important;
  // line-height: 1.8;
  @media only screen and (max-width: 800px) {
    font-size: 14px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 13px !important;
  }
}

.l-text-detail-2 {
  font-size: 16px !important;
  // line-height: 1.8;
  @media only screen and (max-width: 800px) {
    font-size: 14px !important;
  }
  @media only screen and (max-width: 500px) {
    font-size: 13px !important;
  }
}

.l-text-description {
  font-size: 14px !important;
  // line-height: 1.8;
  @media only screen and (max-width: 500px) {
    font-size: 12px !important;
  }
}

.l-text-description-2 {
  font-size: 12px !important;
  // line-height: 1.8;
  @media only screen and (max-width: 500px) {
    font-size: 10px !important;
  }
}

.border-card {
  border-radius: 10px !important;
  border: 1px solid #d0d0d0 !important;
}
</style>
